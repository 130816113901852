* {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

label {
  margin-bottom: 0;
}

a {
  text-decoration: none !important;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -user-drag: none;
}

button:focus {
  outline: none;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.loading-modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.2);
}

.hidden {
  display: none;
}

.sr-only {
  display: none;
}

.unset-w-h {
  width: unset !important;
  height: unset !important;
}

.common-input {
  padding: 4px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.button-link {
  color: #fff !important;
}

.sketch-picker {
  .flexbox-fix:nth-child(3) {
    display: none !important;
  }
}
