.marker-cluster {
  border-radius: 50%;
  background-color: firebrick;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.scaled-down {
  scale: 0.4;
}

.dragable {
  cursor: move;
}

.leaflet-popup-content-wrapper {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.leaflet-popup-content-wrapper .leaflet-popup-content {
  margin: 9px;
  font-size: 11px;
}
.leaflet-popup.leaflet-zoom-animated {
  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    background: rgba(255, 255, 255, 0.7);
  }
}

.wind-barb {
  width: 60px !important;
  height: 60px !important;
}

.limit-one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.z-index-10001 {
  z-index: 10001 !important;
}

.leaflet-control-scale {
  display: flex;

  div:first-child {
    border-right-width: 0px;
  }

  .leaflet-control-scale-line {
    border: 2px solid #777;
    height: 24px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    font-size: 12px !important;
  }
}

.leaflet-container
  .leaflet-touch
  .leaflet-retina
  .leaflet-fade-anim
  .leaflet-grab
  .leaflet-touch-drag
  .leaflet-touch-zoom {
  position: relative;
  z-index: 1;
}

.leaflet-bottom.leaflet-right {
  display: none;
}

.leaflet-div-icon {
  border: none;
  background: transparent;
}

.leaflet-container a.leaflet-popup-close-button {
  top: 2px;
  right: 2px;
  width: 12px;
  height: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaflet-div-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leaflet-container .popup-hidden-close-button a.leaflet-popup-close-button {
  display: none !important;
}

.leaflet-disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
}

.marker-cluster {
  width: 40px;
  height: 40px;
  background-color: cadetblue;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fefefe;
}

.leaflet-container {
  width: 80vw;
  height: 100%;
}

.leaflet-control-attribution svg {
  display: none !important;
}

.leaflet-container .leaflet-control-mouseposition {
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 5px #bbb;
  padding: 0 5px;
  margin: 0;
  color: #333;
  font:
    15px/1.5 "Helvetica Neue",
    Arial,
    Helvetica,
    sans-serif;
}

.leaflet-marker-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-tracking {
  .leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive {
    opacity: 0.4;
    &.leaflet-tracking-div-icon {
      opacity: 1;
    }
  }
}

.leaflet-animation-ship-element {
  position: absolute;
  top: 0;
  left: 20px;
  width: 200px;
  font-size: 12px;
}

.leaflet-tracking-div-icon {
  width: 12px !important;
  height: 12px !important;
}

.weather-info-tooltip {
  padding: 0;
  font-size: 13px;
  border-radius: 4px;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
  &.leaflet-popup.leaflet-zoom-animated .leaflet-popup-content-wrapper,
  &.leaflet-popup.leaflet-zoom-animated .leaflet-popup-tip {
    padding: 0;
    background-color: rgba(255, 255, 255);

    .leaflet-popup-content {
      margin: 0 4px;

      .weather-info-tooltip-header {
        background-color: #1976d2;
        color: #fff;
        padding: 8px 10px 8px 4px;
        width: calc(100% + 8px);
        margin-left: -4px;
        font-size: 14px;
        margin-bottom: 4px;
      }
    }
  }

  a.leaflet-popup-close-button {
    color: white;
    font-size: 16px;
    right: 6px;
    top: 6px;

    &:hover {
      color: aliceblue;
    }
  }
}
